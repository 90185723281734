import 'react-perfect-scrollbar/dist/css/styles.css'
import React from 'react'
import {useRoutes} from 'react-router-dom'
import {ThemeProvider} from '@material-ui/core'
import GlobalStyles from './components/global-styles'
import './mixins/chartjs'
import theme from './theme'
import routes from './routes'

const App = () => {
  const routing = useRoutes(routes)
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles>
        {routing}
      </GlobalStyles>
    </ThemeProvider>
  )
}

export default App
